import Component from './Component';

class WorkTeaser extends Component {
  constructor(element) {
    super(element);

    element.addEventListener('canplaythrough', () => {
      element.play();
    });

    this.load();
  }

  load() {
    const sourceElements = this.element.querySelectorAll('source');
    [...sourceElements].forEach((sourceElement) => {
      if (!sourceElement.hasAttribute('media')
      || window.matchMedia(sourceElement.getAttribute('media')).matches) {
        sourceElement.setAttribute('src', sourceElement.dataset.src);
        sourceElement.removeAttribute('data-src');
      }
    });
    this.element.load();
  }
}

[...document.querySelectorAll('.work-teaser-video')].forEach(element => new WorkTeaser(element));
