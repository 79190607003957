/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import gsap from 'gsap';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Component from './Component';

class WorkTeaserSchautThree extends Component {
  constructor(element) {
    super(element);
    this.element = element;
    this.parentWorkTeaser = element.closest('.work-teaser');
    this.canvasElement = element.querySelector('canvas');
    this.nextElement = this.parentWorkTeaser.nextElementSibling;

    this.toRadians = angle => angle * (Math.PI / 180);

    // constants
    this.finalRotationOfPackaging = -107;

    // variables
    this.scrollTop = 0;
    this.isDragging = false;
    this.canvasAspectRatio = null;
    this.schautRota = {
      targetRotationX: 0,
      targetRotationOnMouseDownX: 0,
      targetRotationY: 0,
      targetRotationOnMouseDownY: 0,
      mouseX: 0,
      mouseXOnMouseDown: 0,
      mouseY: 0,
      mouseYOnMouseDown: 0,
      lastRotationX: 0,
      lastRotationY: 0,
    };
    this.marginTop = null;
    this.scrollYCenter = 0;
    this.cursor = {
      x: window.innerWidth * 0.5,
      y: window.innerWidth * 0.5,
    };
    this.damped_mouse = {
      x: 0,
      y: 0,
    };
    this.boundingClientRect = null;

    // three elements
    // initialised in DOMContentLoaded event
    this.renderer = null;
    this.scene = null;
    this.camera = null;

    // three objects
    this.packagingMesh = null; // added with gltfLoader
    this.plasticSheet = new THREE.Group();
    this.interactionGroup = new THREE.Group(); // includes packagingMesh and plasticSheet
    this.modelGroup = new THREE.Group(); // includes interactionGroup
    this.innerGroup = new THREE.Group(); // includes modelGroup
    this.lightsGroup = new THREE.Group(); // includes ambientLight and three point lights

    // three specific variables
    // set in initRenderer()
    this.maxAnisotropy = null;

    // event listener
    this.renderer = this.initRenderer();
    this.maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();
    this.scene = this.initScene();
    this.onResize();

    document.addEventListener('scroll', this.onScroll.bind(this), {
      passive: true,
    });
    window.addEventListener('mousedown', this.onMousedown.bind(this));
    window.addEventListener('touchstart', this.onMousedown.bind(this));
    window.addEventListener('mouseup', this.onMouseup.bind(this));
    window.addEventListener('touchend', this.onMouseup.bind(this));
    window.addEventListener('mousemove', this.onMousemove.bind(this));
    window.addEventListener('touchmove', this.onMousemove.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));

    console.log('constructor WorkTeaserSchautThree');
  }

  render() {
    // use rect of the element that is a place holder (set while scrolling)
    const {
      schautRota,
      damped_mouse,
      renderer,
    } = this;

    // Groups
    const {
      innerGroup,
      lightsGroup,
      interactionGroup,
    } = this;

    const parallaxPositionY = (this.scrollYCenter * -0.00001) - 0.01;
    const parallaxRotationY = this.scrollYCenter * 0.0001;
    const parallaxRotationX = this.scrollYCenter > 0 ? this.scrollYCenter * -0.001 : this.scrollYCenter * -0.0002;


    if (this.isInView) {
      gsap.to(innerGroup.position, {
        duration: 0.6,
        y: parallaxPositionY,
        overwrite: true,
        ease: 'Power1.easeOut',
      });

      // horizontal rotation
      interactionGroup.rotation.y += (schautRota.targetRotationX - interactionGroup.rotation.y + parallaxRotationY) * 0.1;
      innerGroup.rotation.x += (schautRota.targetRotationY - innerGroup.rotation.x + parallaxRotationX) * 0.075;

      // dynamic tilt of model based on cursor
      gsap.to(innerGroup.rotation, {
        duration: 0.8,
        y: this.toRadians((damped_mouse.x * (12.5))),
        x: this.toRadians((damped_mouse.y * (-17.5))),
        overwrite: true,
        ease: 'Power1.easeOut',
      });

      // parallax outer light based on cursor
      gsap.to(lightsGroup.children[1].position, {
        duration: 0.8,
        y: ((-0.01) + (damped_mouse.y * 1.75)), // top-bottom
        overwrite: true,
        ease: 'Power1.easeOut',
      });

      renderer.render(this.scene, this.camera);
    }
  }

  tick() {
    const {
      renderer,
    } = this;

    this.render();

    // loop to create render-timeline
    renderer.setAnimationLoop(this.tick.bind(this)); // XR capable
  }

  initRenderer() {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvasElement,
      alpha: true,
      antialias: window.devicePixelRatio <= 1,
      // physicallyCorrectLights: true,
      // powerPreference: 'high-performance',
      preserveDrawingBuffer: true,
    });

    // renderer.shadowMap.enabled = true;
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.format = THREE.RGBAFormat;
    renderer.toneMapping = THREE.NoToneMapping;
    renderer.needupdate = true;

    return renderer;
  }

  initScene() {
    const scene = new THREE.Scene();
    scene.background = null;
    scene.name = 'Schaut';

    const loadingManager = new THREE.LoadingManager(
      () => {
        this.tick();
      },
    );

    const textureLoader = new THREE.TextureLoader(loadingManager);
    const gltfLoader = new GLTFLoader(loadingManager);

    this.camera = new THREE.PerspectiveCamera(45, this.canvasAspectRatio, 0.1, 15);
    this.camera.position.z = 1;

    // model

    // normal Map
    let rough_normalMap = null;
    rough_normalMap = textureLoader.load('assets/start-2022/3d/UVs/normal_map_dirt.jpg');
    rough_normalMap.wrapS = THREE.RepeatWrapping;
    rough_normalMap.wrapT = THREE.RepeatWrapping;
    const normalMapScaling = new THREE.Vector2(0.1, 0.1); // depth -> describes Intensity

    let schaut_texture_src = 'assets/start-2022/3d/textures/schaut_verpackung/uv_clean_high-res.jpg';
    if (window.matchMedia('(max-height: 700px)').matches) {
      schaut_texture_src = 'assets/start-2022/3d/textures/schaut_verpackung/uv_clean_high-res@0.5x.jpg';
    }
    const schaut_texture = textureLoader.load(
      schaut_texture_src,
      (texture) => {
        texture.encoding = THREE.sRGBEncoding;
        texture.format = THREE.RGBAFormat;
      },
      (error) => { console.log(error); },
    );
    schaut_texture.flipY = false;

    schaut_texture.anisotropy = this.maxAnisotropy; // improve render quality of texture based on GraCa
    schaut_texture.generateMipmaps = true;

    const schaut_material = new THREE.MeshPhysicalMaterial({
      map: schaut_texture,
      side: THREE.DoubleSide,
      transparent: false, // ironically this is needed to make the window transparent!
      opacity: 1.0,
      metalness: 0.16,
      roughness: 0.6,
      thickness: 1.0,
      ior: 1.25,
      normalMap: rough_normalMap,
      normalScale: normalMapScaling,
      attenuationColor: new THREE.Color().setHex(0xFFE79E, THREE.SRGBColorSpace).convertSRGBToLinear(),
      attenuationDistance: 0.0,
    });

    gltfLoader.load(
      'assets/start-2022/3d/models/Schaut_Verpackung/20220520_Schaut_Verpackung_separated.gltf',
      (gltf) => {
        const children = [...gltf.scene.children];
        // go through elements in the file
        children.every((child) => {
          if (child instanceof THREE.Mesh) {
            this.packagingMesh = child;
            this.packagingMesh.material = schaut_material;
            this.packagingMesh.receiveShadow = true;
            this.packagingMesh.castShadow = true;
            this.interactionGroup.add(this.packagingMesh);
            return false;
          }
          return true;
        });
        console.log('3d object loaded');
      },
      (error) => { console.log(error); },
    );

    // add transparent plastic sheet to the model
    const plastic_sheet_material = new THREE.MeshPhysicalMaterial({
      color: new THREE.Color().setHex(0xFFFFFF, THREE.SRGBColorSpace).convertSRGBToLinear(),
      transparent: true,
      opacity: 0.2,
      metalness: 0.6,
      roughness: 0.1,
      transmission: 0.2,
      clearcoat: 0.1,
      clearcoatRoughness: 0.2,
      thickness: 1.0,
      ior: 1.25,
      normalMap: rough_normalMap,
      normalScale: normalMapScaling,
      clearcoatNormalMap: rough_normalMap,
      clearcoatNormalScale: normalMapScaling,
      attenuationColor: new THREE.Color().setHex(0xFFE79E, THREE.SRGBColorSpace).convertSRGBToLinear(),
      attenuationDistance: 0.0,
    });
    const plastic_sheet = new THREE.Mesh(
      new THREE.BoxGeometry(0.075, 0.1, 0.075), // depth, height, width
      plastic_sheet_material,
    );
    plastic_sheet.receiveShadow = true;
    plastic_sheet.castShadow = false;
    plastic_sheet.position.set(0.01, -0.04, 0); // front-back, top-bottom, left-right
    this.plasticSheet.add(plastic_sheet);
    this.plasticSheet.name = 'Additional Plastic Sheet';
    this.interactionGroup.add(this.plasticSheet);

    const basic_inner_material = new THREE.MeshStandardMaterial({
      color: new THREE.Color().setHex(0x980A0B, THREE.SRGBColorSpace).convertSRGBToLinear(),
      side: THREE.DoubleSide,
      transparent: false,
    });
    const inner_black_box = new THREE.Mesh(
      new THREE.BoxGeometry(0.099, 0.09, 0.099),
      basic_inner_material,
    );
    inner_black_box.receiveShadow = false;
    inner_black_box.castShadow = false;
    inner_black_box.position.set(0, 0.0599, 0);
    inner_black_box.name = 'inner box for look-through';
    this.interactionGroup.add(inner_black_box);

    this.interactionGroup.position.set(0, 0.01, 0);

    this.modelGroup.add(this.interactionGroup);
    this.modelGroup.rotation.set(0, this.toRadians(this.finalRotationOfPackaging), 0);
    this.modelGroup.scale.set(1, 1, 1);
    this.modelGroup.name = 'Schaut Model';

    // lights
    this.lightsGroup.name = 'Lights';
    const ambientLight = new THREE.AmbientLight(new THREE.Color().setHex(0xFFFFFF, THREE.SRGBColorSpace).convertSRGBToLinear(), 1.16);
    ambientLight.name = 'Ambient Light (general)';
    this.lightsGroup.add(ambientLight);

    const pointLightGrid1 = new THREE.PointLight(new THREE.Color().setHex(0xFFFFFF, THREE.SRGBColorSpace).convertSRGBToLinear(), 3.618, 4, 2);
    pointLightGrid1.position.set(0, -0.01, 3);
    pointLightGrid1.name = 'PointLight for reflection (MAIN)';
    this.lightsGroup.add(pointLightGrid1);

    const pointLightGrid2 = new THREE.PointLight(new THREE.Color().setHex(0xFFFFFF, THREE.SRGBColorSpace).convertSRGBToLinear(), 2.618, 4, 2);
    pointLightGrid2.position.set(-3, 0.02, 1.5);
    pointLightGrid2.name = 'PointLight for reflection (LE)';
    this.lightsGroup.add(pointLightGrid2);

    const pointLightGrid3 = new THREE.PointLight(new THREE.Color().setHex(0xFFFFFF, THREE.SRGBColorSpace).convertSRGBToLinear(), 1.618, 4, 2);
    pointLightGrid3.position.set(3, 0.2, 1.5);
    pointLightGrid3.name = 'PointLight for reflection (RE)';
    this.lightsGroup.add(pointLightGrid3);

    // group stuff and add everything together
    this.innerGroup.add(this.modelGroup);
    this.innerGroup.position.set(0, 0, 0.63);
    this.innerGroup.name = 'Schaut Inner';

    // adds meshes and lights
    scene.add(this.innerGroup);
    scene.add(this.lightsGroup);

    // add fog to soften scene
    scene.fog = new THREE.FogExp2(0xF0F0F1, 0.01618);

    console.log('scene init complete');

    return scene;
  }

  resizeWebGl() {
    const {
      camera,
      canvasAspectRatio,
    } = this;

    camera.aspect = canvasAspectRatio;
    camera.updateProjectionMatrix();
  }

  resizeRendererToDisplaySize() {
    const { renderer } = this;
    // reset canvas sizes
    this.canvasElement.removeAttribute('width');
    this.canvasElement.removeAttribute('height');

    const width = this.canvasElement.offsetWidth;
    const height = this.canvasElement.offsetHeight;
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setSize(width, height, false);
  }

  checkIfIsInView() {
    return this.isInView;
  }

  get isInView() {
    this.rect = this.parentWorkTeaser.getBoundingClientRect();
    const is = (this.rect.top - this.marginTop) < this.windowInnerHeight && this.rect.bottom >= 0;

    // DEBUG
    if (is) {
      this.element.dataset.isInView = 'true';
    } else {
      this.element.dataset.isInView = 'false';
    }
    return is;
  }

  onMousedown(event) {
    const {
      windowInnerWidth,
      windowInnerHeight,
    } = this;
    this.isDragging = true;

    // if mouse
    if (event.clientX) {
      this.schautRota.mouseXOnMouseDown = event.clientX - (windowInnerWidth / 2);
      this.schautRota.mouseYOnMouseDown = event.clientY - (windowInnerHeight / 2);
    }

    // if touch
    if (event.targetTouches && event.targetTouches[0]) {
      const touch = event.targetTouches[0];
      // use only x axis. y axis is used for normal scrolling.
      this.schautRota.mouseXOnMouseDown = touch.screenX - (windowInnerWidth / 2);
    }

    this.schautRota.targetRotationOnMouseDownX = this.schautRota.targetRotationX;
    this.schautRota.targetRotationOnMouseDownY = this.schautRota.targetRotationY;
  }


  onMouseup() {
    this.isDragging = false;
  }

  onMousemove(event) {
    const {
      innerHeight,
      innerWidth,
    } = window;

    // if mouse
    if (event.clientX) {
      this.cursor.x = event.clientX;
      this.cursor.y = event.clientY;
    }

    // if touch
    if (event.targetTouches && event.targetTouches[0]) {
      const touch = event.targetTouches[0];
      // use only x axis. y axis is used for normal scrolling.
      this.cursor.x = touch.screenX;
    }

    // cover -> damped model-movement based on mouse
    this.damped_mouse.x = (this.cursor.x / innerWidth) * 2 - 1;
    this.damped_mouse.y = -(this.cursor.y / innerHeight) * 2 + 1;

    if (this.isDragging === true) {
      this.schautRota.mouseX = this.cursor.x - (innerWidth / 2);
      this.schautRota.mouseY = this.cursor.y - (innerHeight / 2);

      // vertical rotation (limited to prevent bad angles)
      const rotaVal = this.schautRota.targetRotationOnMouseDownY + (this.schautRota.mouseY - this.schautRota.mouseYOnMouseDown) * 0.005;
      if (rotaVal > 0.6) {
        this.schautRota.targetRotationY = 0.6;
      } else if (rotaVal < -0.25) {
        this.schautRota.targetRotationY = -0.25;
      } else {
        this.schautRota.targetRotationY = this.schautRota.targetRotationOnMouseDownY + (this.schautRota.mouseY - this.schautRota.mouseYOnMouseDown) * 0.005;
      }

      // horizontal rotation
      this.schautRota.targetRotationX = this.schautRota.targetRotationOnMouseDownX + (this.schautRota.mouseX - this.schautRota.mouseXOnMouseDown) * 0.004;
    }
  }

  onResize() {
    const style = getComputedStyle(this.element);
    this.marginTop = parseFloat(style.marginTop);
    this.canvasAspectRatio = this.canvasElement.clientWidth / this.canvasElement.clientHeight;

    this.resizeWebGl();
    this.resizeRendererToDisplaySize();
  }

  onScroll() {
    const { windowInnerHeight } = this;
    this.checkIfIsInView();
    this.innerHeight = windowInnerHeight - 53; // 53 is height of mainnav
    this.scrollTop = document.scrollingElement.scrollTop - windowInnerHeight;
    this.scrollYCenter = this.rect.top + this.innerHeight;
  }
}

const element = document.querySelector('.work-teaser-schaut-three');

if (element) {
  window.addEventListener('load', () => {
    setTimeout(() => {
      new WorkTeaserSchautThree(element);
    }, 100);
  });
}
