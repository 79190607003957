import Component from './Component';

class IntroClaim extends Component {
  constructor(element) {
    super(element);
    this.element = element;
    this.digitalesElement = element.querySelector('[data-target="digitales"]');

    this.shuffleParameters = {
      skills: [
        'alles Digitale',
        'Websites',
        'Onlineshops',
        'User Interfaces',
        'SEO',
        'SEA',
        'Apps',
        'Social Media',
        '3D',
        'Virtual Reality',
        'alles Digitale',
      ].map(s => `${s}`),
      tail: 4, // how many chars are shuffling
      skillP: 14, // length 'alles digitale'
      text: 'alles Digitale',
      delay: 4,
      step: 1,
      timeout: 100,
    };

    this.colors = [
      '#399165',
      '#5CA37F',
      '#72AD8F',
      '#89B8A1',
    ];

    this.$ = {
      text: this.shuffleParameters.text,
      skill: 0,
      skillI: 0,
      skillP: this.shuffleParameters.skillP,
      direction: 'backward',
      delay: this.shuffleParameters.delay,
      step: undefined,
    };

    // test
    setTimeout(() => {
      this.updateDigitales();
    }, 3160);
  }

  getRandomColor() {
    return this.colors[Math.floor(Math.random() * this.colors.length)];
  }

  getRandomChar() {
    return String.fromCharCode(Math.random() * (127 - 33) + 33);
  }

  getRandomColoredString(n) {
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < n; i++) {
      const char = document.createElement('span');
      char.textContent = this.getRandomChar();
      char.style.color = this.getRandomColor();
      fragment.appendChild(char);
    }
    return fragment;
  }

  updateDigitales() {
    if (this.isInView) {
      const skill = this.shuffleParameters.skills[this.$.skillI];

      if (this.$.step) {
        this.$.step--;
      } else if (this.$.direction === 'forward') {
        // forward (show word)
        if (this.$.skillP < skill.length) {
          this.$.text += skill[this.$.skillP];
          this.$.skillP++;
        } else if (this.$.delay) {
          this.$.delay--;
        } else {
          if (this.$.skillI == (this.shuffleParameters.skills.length - 1)) {
            // reset

            this.$.text = 'alles digitale';
            this.$.skill = 0;
            this.$.skillI = 0;
            this.$.skillP = this.shuffleParameters.skillP;
            this.$.direction = 'backward';
            this.$.delay = this.shuffleParameters.delay;
            this.$.step = undefined;

            setTimeout(() => {
              this.updateDigitales();
            }, 3160);

            return false;
          }
          this.$.direction = 'backward';
          this.$.delay = this.shuffleParameters.delay;
        }
      } else {
        // backward (remove word)
        if (this.$.skillP > 0) {
          this.$.text = this.$.text.slice(0, -1);
          this.$.skillP--;
        } else {
          this.$.skillI = (this.$.skillI + 1) % this.shuffleParameters.skills.length;
          this.$.direction = 'forward';
        }
      }

      this.digitalesElement.textContent = this.$.text;
      this.digitalesElement.appendChild(this.getRandomColoredString(Math.min(this.shuffleParameters.tail, skill.length - this.$.skillP)));
    }

    setTimeout(() => {
      this.updateDigitales();
    }, this.shuffleParameters.timeout);
  }
}

const element = document.querySelector('.intro-claim');
if (element) {
  new IntroClaim(element);
}
