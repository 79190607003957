import Component from './Component';

class WorkTeaserSlides extends Component {
  constructor(element) {
    super(element);
    this.imageElements = element.querySelectorAll('img');

    this.imagesCount = this.imageElements.length;
    this.relativeMouseX = 0.5;

    window.addEventListener('orientationchange', this.onResize.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));
    window.addEventListener('load', this.onResize.bind(this));
    window.addEventListener('mousemove', this.onMousemove.bind(this));
    window.addEventListener('touchmove', this.onTouchmove.bind(this));

    this.tick();
    this.updateImage();
  }

  tick() {
    if (this.isInView) {
      this.updateImage();
    }
    requestAnimationFrame(this.tick.bind(this));
  }

  updateImage() {
    const imageIndexToShow = Math.floor(this.imagesCount * this.relativeMouseX);
    this.imageElements.forEach((imageElement, index) => {
      if (index === imageIndexToShow) {
        imageElement.classList.remove('-hidden');
      } else {
        imageElement.classList.add('-hidden');
      }
    });
  }

  get isInView() {
    this.rect = this.element.getBoundingClientRect();
    const is = (this.rect.top - this.marginTop) < this.windowInnerHeight && this.rect.bottom >= 0;

    // DEBUG
    if (is) {
      this.element.dataset.isInView = 'true';
    } else {
      this.element.dataset.isInView = 'false';
    }
    return is;
  }

  onTouchmove(event) {
    this.relativeMouseX = event.targetTouches[0].screenX / window.innerWidth;
  }

  onMousemove(event) {
    this.relativeMouseX = event.clientX / window.innerWidth;
  }

  onResize() {
    const style = getComputedStyle(this.element);
    this.marginTop = parseFloat(style.marginTop);
  }
}

[...document.querySelectorAll('.work-teaser-slides')].forEach(element => new WorkTeaserSlides(element));
