class Component {
  constructor(element) {
    this.element = element;
    this.windowInnerWidth = window.innerWidth;
    this.windowInnerHeight = window.innerHeight;

    window.addEventListener('orientationchange', this.onResize.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    this.windowInnerWidth = window.innerWidth;
    this.windowInnerHeight = window.innerHeight;
  }

  get isInView() {
    const rect = this.element.getBoundingClientRect();
    const is = rect.top < this.windowInnerHeight && rect.bottom >= 0;

    // DEBUG
    // if (is) {
    //   this.element.dataset.isInView = 'true';
    // } else {
    //   this.element.dataset.isInView = 'false';
    // }
    return is;
  }

  get willSoonBeInView() {
    const rect = this.element.getBoundingClientRect();
    const is = rect.top < this.windowInnerHeight * 2 && rect.bottom >= this.windowInnerHeight * -1;
    // DEBUG
    // if (is) {
    //   this.element.dataset.willSoonBeInView = 'true';
    // } else {
    //   this.element.dataset.willSoonBeInView = 'false';
    // }
    return is;
  }

  // helper method for loading a video element
  loadVideo() {
    const { videoElement } = this;
    if (videoElement) {
      videoElement.addEventListener('canplaythrough', () => {
        videoElement.classList.remove('is-not-loaded');
        if (videoElement.paused) {
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch(reasons => console.log(reasons));
          }
        }
      });
      videoElement.addEventListener('play', () => {
        videoElement.classList.remove('is-not-loaded');
      });
      const sourceElements = videoElement.querySelectorAll('source');
      [...sourceElements].forEach((sourceElement) => {
        if (!sourceElement.hasAttribute('media')
        || window.matchMedia(sourceElement.getAttribute('media')).matches) {
          sourceElement.setAttribute('src', sourceElement.dataset.src);
          sourceElement.removeAttribute('data-src');
        }
      });
      videoElement.load();
    }
  }
}

export default Component;
