import Component from './Component';

class WorkTeaser extends Component {
  constructor(element) {
    super(element);

    this.linkElement = element.querySelector('.work-teaser__link');
    this.containerStickyElement = element.querySelector('.work-teaser__container-sticky');
    this.containerFigureElement = element.querySelector('.work-teaser__figure');
    this.elementHeight = null;
    this.marginTop = null;
    this.mainnavHeight = 53;

    this.linkElement.addEventListener('transitionend', () => {
      if (this.linkElement.classList.contains('-hidden')) {
        if(!window.scrollY === 0){
          this.linkElement.setAttribute('hidden', '');
        }
      }
    });
    document.addEventListener('scroll', this.onScroll.bind(this), {
      passive: true,
    });
    window.addEventListener('resize', this.onResize.bind(this));
    window.addEventListener('load', this.onScroll.bind(this));
    window.addEventListener('load', this.onResize.bind(this));
  }

  animateElement() {
    const scrollYBottomPlus40Percent = this.scrollYBottom + this.innerHeight * 0.4;
    const scrollYBottomPlus50Percent = this.scrollYBottom + this.innerHeight * 0.5;
    const scrollYBottomMinus50Percent = this.scrollYBottom - this.innerHeight * (1 - 0.5);

    let opacitySticky = 1;
    const translateYContainerFigure = this.scrollYBottom < 0
      ? (this.scrollYBottom * -0.1) ** 1.3
      : this.scrollYBottom * 0.1;
    let opacityLink = 1;
    let translateYLink = 0;
    if (!window.matchMedia(`(max-width: ${544 / 16}em)`).matches) {
      opacityLink = scrollYBottomPlus40Percent < 0
        ? 1 - ((scrollYBottomPlus40Percent * -0.02) ** 5)
        : 1;
      translateYLink = scrollYBottomPlus40Percent < 0
        ? (scrollYBottomPlus40Percent * 0.09) ** 2
        : 0;
    }

    if (scrollYBottomPlus50Percent < 0) {
      opacitySticky = 1 - scrollYBottomPlus50Percent * -0.001;
    } else if (scrollYBottomMinus50Percent > 0) {
      opacitySticky = 1 - scrollYBottomMinus50Percent * 0.002;
    }
    requestAnimationFrame(() => {
      this.containerStickyElement.style.opacity = opacitySticky;
      this.containerFigureElement.style.transform = `translateY(${translateYContainerFigure}px)`;
      this.linkElement.style.opacity = opacityLink;
      this.linkElement.style.transform = `translateY(${translateYLink}px)`;
    });
  }

  toggleLinkElement() {
    if (!window.matchMedia(`(max-width: ${544 / 16}em)`).matches) {
      if (this.scrollYCenter < 0) {
        this.linkElement.removeAttribute('hidden');
        requestAnimationFrame(() => {
          this.linkElement.classList.remove('-hidden');
        });
      } else {
        this.linkElement.classList.add('-hidden');
      }
    } else {
      this.linkElement.removeAttribute('hidden');
      requestAnimationFrame(() => {
        this.linkElement.classList.remove('-hidden');
      });
    }
  }

  get isInView() {
    this.rect = this.element.getBoundingClientRect();
    const is = (this.rect.top - this.marginTop) < this.windowInnerHeight && this.rect.bottom >= 0;

    // DEBUG
    if (is) {
      this.element.dataset.isInView = 'true';
    } else {
      this.element.dataset.isInView = 'false';
    }
    return is;
  }

  onResize() {
    const style = getComputedStyle(this.element);
    this.marginTop = parseFloat(style.marginTop);

    if (window.matchMedia(`(max-width: ${960 / 16}em)`).matches) {
      this.mainnavHeight = 0;
    } else {
      this.mainnavHeight = 53;
    }
  }

  onScroll() {
    if (this.isInView) {
      const { rect, mainnavHeight, windowInnerHeight } = this;
      // use innerHeight of window instead of windowInnerHeight,
      // because innerHeight might change on iOS while scrolling.
      const { innerHeight } = window;
      this.innerHeight = windowInnerHeight - mainnavHeight; // 53 is height of mainnav

      // is 0 when element is in center of screen
      // is getting negative when element is past center of screen
      this.scrollYCenter = rect.top + innerHeight * 0.5;

      this.scrollYBottom = rect.top + innerHeight;

      this.element.classList.add('-will-change');
      this.toggleLinkElement();
      this.animateElement();
    } else {
      this.element.classList.remove('-will-change');
    }
  }
}


[...document.querySelectorAll('.work-teaser')].forEach(element => new WorkTeaser(element));
